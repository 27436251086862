import React, { useState, useEffect } from 'react';
import useLocalStorageState from '../customhooks/useLocalStorageState';

export const hbfContext = React.createContext();

const Provider = (props) => {
  const [zipCode, setZipCode] = useLocalStorageState('zipCode', '');
  const [activeStep, setActiveStep] = useState('offers');
  const [locationUrl, setLocationUrl] = useLocalStorageState('locationUrl', '/');
  const [phone, setPhone] = useLocalStorageState('phone', '866-881-2743');
  
  // Update selectedOffers to load from local storage if available
const [selectedOffers, setSelectedOffers] = useState([]) // Initialize with an empty array

useEffect(() => {
  const savedOffers = localStorage.getItem('selectedOffers') // Access localStorage only in the browser
  if (savedOffers) {
    setSelectedOffers(JSON.parse(savedOffers))
  }
  }, []) // Empty dependency array to run only once on mount

  const [selectedCustomSelectors, setSelectedCustomSelectors] = useState([]);
  const [selectedDate, setSelectedDate] = useState({});
  const [selectedPreference, setSelectedPreference] = useState('morning');
  const [availableDates, setAvailableDates] = useState([]);
  const [unAvailableDates, setUnAvailableDates] = useState([]);
  const [topThreeReviews, setTopThreeReviews] = useState([]);
  const [totalReviewCount, setTotalReviewCount] = useState(0);
  const [averageRating, setAverageRating] = useState(5.0);
  const [orderSummary, setOrderSummary] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [successUrl, setSuccessUrl] = useState(null);
  const [cityState, setCityState] = useLocalStorageState('cityState', {
    city: '',
    stateCode: ''
  });

  const [corporateOwnerId, setCorporateOwnerId] = useState('');
  const [ownerFirstName, setOwnerFirstName] = useState('');
  const [ownerLastName, setOwnerLastName] = useState('');
  
  // Sync `selectedOffers` to local storage after every update
  useEffect(() => {
    // Save only if selectedOffers changes to avoid overwriting with outdated data
    if (selectedOffers) {
      localStorage.setItem('selectedOffers', JSON.stringify(selectedOffers));
    }
  }, [selectedOffers]);

  return (
    <hbfContext.Provider
      value={{
        zipCode,
        showModal,
        successUrl,
        locationUrl,
        phone,
        selectedOffers,
        selectedCustomSelectors,
        selectedDate,
        selectedPreference,
        activeStep,
        availableDates,
        unAvailableDates,
        averageRating,
        topThreeReviews,
        totalReviewCount,
        orderSummary,
        cityState,
        corporateOwnerId,
        ownerFirstName,
        ownerLastName,
        changeCorporateOwnerId: (value) => setCorporateOwnerId(value),
        changeOwnerFirstName: (value) => setOwnerFirstName(value),
        changeOwnerLastName: (value) => setOwnerLastName(value),
        changeShowModal: (value) => setShowModal(value),
        changeSuccessUrl: (value) => setSuccessUrl(value),
        changeOrderSummary: (value) => setOrderSummary(value),
        changeAvailableDates: (value) => setAvailableDates(value),
        changeUnAvailableDates: (value) => setUnAvailableDates(value),
        changeActiveStep: (value) => setActiveStep(value),
        changeSelectedDate: (value) => setSelectedDate(value),
        changeSelectedPreference: (value) => setSelectedPreference(value),
        changeZipCode: (value) => setZipCode(value),
        changePhone: (value) => setPhone(value),
        changeLocationUrl: (value) => setLocationUrl(value),
        addSelectedOffer: (offer, source = 'local-offers') =>
          setSelectedOffers((currentOffers) => [
            ...currentOffers,
            { ...offer, source }
          ]),        
        addCustomSelector: (customSelector) =>
          setSelectedCustomSelectors((currentCustomSelectors) => [
            ...currentCustomSelectors,
            customSelector,
          ]),
        removeSelectedOffer: (featureId) =>
          setSelectedOffers(
            selectedOffers.filter((item) => {
              return item.featureId !== featureId;
            })
          ),
        removeSelectedCustomSelector: (itemId) =>
          setSelectedCustomSelectors(
            selectedCustomSelectors.filter((item) => {
              return item.itemId !== itemId;
            })
          ),
        updateSelectedOfferQuantity: (updatedSelectedOffers) =>
          setSelectedOffers(updatedSelectedOffers),
        
        // Clear selectedOffers and local storage
        removeAllSelectedOffers: () => {
          setSelectedOffers([]);
          localStorage.removeItem('selectedOffers');
        },
        
        removeAllSelectedCustomSelectors: () => setSelectedCustomSelectors([]),
        changeTopThreeReviews: (value) => setTopThreeReviews(value),
        changeAverageRating: (value) => setAverageRating(value),
        changeTotalReviewCount: (value) => setTotalReviewCount(value),
        updateCityState: (value) => setCityState(value),
      }}
    >
      {props.children}
    </hbfContext.Provider>
  );
};
const CleanProvider = ({ element }) => <Provider>{element}</Provider>;
export default CleanProvider;
